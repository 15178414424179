import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Seo from '../components/seo';

const ArenaPage = function () {
  return (
    <Layout>
      <Seo
        title="The Arena"
        description="  The 160 by 85-foot arena is fully insulated to keep both rider
        and horse – and spectator, for that matter – warm in the
        winter and cool in the summer."
      />

      <div className="relative max-w-7xl mx-auto pt-16 px-4 sm:px-6 lg:px-8 ">
        <div className="mx-auto text-base max-w-prose lg:hidden  ">
          <div>
            <h2 className="text-base text-brand-blue-100 font-semibold tracking-wide uppercase">
              Luxurious Comfort Awaits
            </h2>
            <h1 className="heading-serif">
              Step inside the arena at Southern Cross Luxurious Comfort Awaits.
            </h1>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none ">
              <StaticImage
                src="../images/the-arena.png"
                quality={95}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Southern Cross Arena"
                loading="eager"
                className="rounded-lg shadow-lg object-cover object-center"
              />
            </div>
          </div>
          <div className="mt-8 lg:mt-0 ">
            <div className="md:max-w-2xl pl-2 lg:p-0 mx-auto hidden lg:grid">
              <h2 className="text-base text-brand-blue-100 font-semibold tracking-wide uppercase ">
                Luxurious Comfort Awaits
              </h2>
              <h3 className="heading-serif">
                Step inside the arena at Southern Cross
              </h3>
            </div>
            <div className="mt-5 prose prose-lg prose-indigo text-gray-500 mx-auto md:max-w-prose g:max-w-none lg:row-start-1 lg:col-start-1">
              <p>
                The 160 by 85-foot arena is fully insulated to keep both rider
                and horse – and spectator, for that matter – warm in the winter
                and cool in the summer. There is even a heated viewing room
                compete with Wi-Fi.
              </p>

              <h4 className="">Notable features:</h4>
              <ul>
                <li>Euro footing has been installed in the riding area.</li>
                <li>
                  The felt is made with short fibers that are mixed with sand to
                  create a cushioning and moisture retaining footing
                </li>
                <li>
                  The watering system combined with the fibers means you have a
                  dust free ride every time
                </li>

                <li>
                  The arena is harrowed twice a week to ensure the best riding
                  conditions
                </li>
              </ul>
              <p className="italic text-center">No stone is left unturned.</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ArenaPage;
